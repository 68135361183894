import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Chapter from '../Chapter';
import SubTopicLayout from '../SubTopicLayout';

class SubTopicFull extends Component {
  static propTypes = {
    data: PropTypes.shape({
      post: PropTypes.object.isRequired,
    }).isRequired,
    onChangeIsSubtopicPage: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { onChangeIsSubtopicPage } = this.props;

    onChangeIsSubtopicPage(true);
  }

  render() {
    const { data } = this.props;

    return (
      <SubTopicLayout
        slug={ data.post.slug }
        yoast={ data.post.yoast }
      >
        <Chapter
          { ...data.post }
          index={ 0 }
          shareCopy='Share this page'
        />
      </SubTopicLayout>
    );
  }
}

export default SubTopicFull;
