import React from 'react';
import PropTypes from 'prop-types';

import ContextConsumer from '../../context/ContextConsumer';
import { GuideProvider } from '../GuideContext';
import SubTopicFull from './SubTopicFull';

const SubTopicFullWrapper = ({ data }) => (
  <ContextConsumer>
    { context => (
      <GuideProvider value={ {
        actions: {},
      } }
      >
        <SubTopicFull
          { ...context.actions }
          data={ data }
        />
      </GuideProvider>
    ) }
  </ContextConsumer>
);

SubTopicFullWrapper.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.object.isRequired,
  }).isRequired,
};

export default SubTopicFullWrapper;
