import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import compose from 'recompose/compose';
import cx from 'classnames';

import { getSubtopicMetaTags } from '../../constants/meta';

import FrameFooter from '../FrameFooter';
import Footer from '../Footer';
import GuideTeaser from '../GuideTeaser';
import Header from '../Header';
import ShareBtns from '../ShareBtns';
import ThemeToggle from '../ThemeToggle';
import withScrollListener from '../withScrollListener';
import withWindowListener from '../withWindowListener';

import '../../styles/index.css';
import styles from './index.module.css';

class SubTopicLayout extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    data: PropTypes.object.isRequired,
    isLarge: PropTypes.bool.isRequired,
    scroll: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    yoast: PropTypes.shape({
      title: PropTypes.string,
      metadesc: PropTypes.string,
    }),
  }

  state = {
    isAtBottom: false,
  }

  componentDidMount() {
    const interval = setInterval(() => {
      if (!!window.Intercom && window.Intercom.booted) {
        window.Intercom('shutdown');

        clearInterval(interval);
      }
    }, 100);
  }

  componentDidUpdate() {
    if (this.wrapperEl) {
      this.updateSidebarStyles();
    }
  }

  render() {
    const {
      children,
      data,
      isLarge,
      slug,
      yoast,
    } = this.props;
    const { isAtBottom } = this.state;

    const wrapperClasses = cx({
      [styles.wrapper]: true,
      [styles.bottom]: isAtBottom,
    });

    return (
      <div>
        <Helmet
          html={ { lang: 'en' } }
          title={ data.site.siteMetadata.title }
          meta={ getSubtopicMetaTags({
            title: yoast.title || data.site.siteMetadata.title,
            description: yoast.metadesc || data.site.siteMetadata.description,
          }) }
          link={ [{ rel: 'stylesheet', href: 'https://use.typekit.net/wms7pqf.css' }] }
        />
        <Header />

        <div className={ wrapperClasses } ref={ r => { this.wrapperEl = r; } }>
          {children}
          { isLarge &&
            <div className={ styles.themeToggle }>
              <ThemeToggle />
            </div>
          }
          { isLarge &&
            <div className={ styles.shareBtns }>
              <ShareBtns />
            </div>
          }
        </div>
        <GuideTeaser slug={ slug } />
        <FrameFooter />
        <Footer />
      </div>
    );
  }

  updateSidebarStyles = () => {
    const { scroll } = this.props;
    const { isAtBottom } = this.state;

    const top = this.wrapperEl.offsetTop;
    const height = this.wrapperEl.offsetHeight;
    const bottom = top + height - window.innerHeight;

    if (scroll >= bottom && !isAtBottom) {
      this.setState({
        isAtBottom: true,
      });
    } else if (scroll < bottom && isAtBottom) {
      this.setState({
        isAtBottom: false,
      });
    }
  }
}

export default compose(
  withScrollListener,
  withWindowListener,
)(SubTopicLayout);
