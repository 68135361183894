import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import ContextConsumer from '../context/ContextConsumer';

import SubTopicFull from '../components/SubTopicFull';

const SubTopicPage = props => (
  <ContextConsumer>
    { context => (
      <SubTopicFull
        { ...props }
        { ...context.state }
        { ...context.actions }
      />
    ) }
  </ContextConsumer>
);

SubTopicPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SubTopicPage;

export const pageQuery = graphql`
  query($id: String!) {
    post: wordpressPost(id: { eq: $id }) {
      id
      slug
      title
      yoast {
        title
        metadesc
      }
      chapter_meta {
        chapter_eyebrow
        chapter_subtitle
        chapter_read_time
        chapter_content {
          content_type
          content_section_heading {
            content_heading
            content_id
          }
          content_p
          content_list
          content_image
          content_inlineCTA {
            content_title
            content_description
            content_ctaCopy
            content_ctaURL
          }
          content_embed {
            content_id
          }
          content_subtopic_slug
          content_subtopic_title
          content_subtopic {
            content_type
            content_section_heading {
              content_heading
              content_id
            }
            content_p
            content_image
            content_list
            content_inlineCTA {
              content_title
              content_description
              content_ctaCopy
              content_ctaURL
            }
          }
        }
      }
    }
  }
`;
