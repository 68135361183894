import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import cx from 'classnames';
import Img from 'gatsby-image';

import withScrollListener from '../withScrollListener';
import withWindowListener from '../withWindowListener';

import styles from './index.module.css';

class Hero extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    isLarge: PropTypes.bool.isRequired,
    localFile: PropTypes.object.isRequired,
    scroll: PropTypes.number.isRequired,
    theme: PropTypes.string.isRequired,
  }

  render() {
    const {
      index,
      isLarge,
      localFile,
      scroll,
      theme,
    } = this.props;

    const top = this.wrapperEl ? this.wrapperEl.getBoundingClientRect().top + scroll : 0;

    const fontSize = isLarge ? 420 - Math.max(0, ((top - 110 - scroll) / -750) * 120) :
      150 - Math.max(0, ((top - 70 - scroll) / -350) * 50);

    const chapterNumber = index + 1;

    const wrapperClasses = cx({
      [styles.wrapper]: true,
      [styles.themeDark]: theme === 'dark',
    });

    const chapterStyles = {
      fontSize,
    };

    return (
      <div className={ wrapperClasses } ref={ r => { this.wrapperEl = r; } }>
        <div className={ styles.inner }>
          <Img
            className={ styles.heroImg }
            fluid={ localFile.childImageSharp.fluid }
            style={ { position: 'absolute' } }
          />
          <div className={ styles.chapterNumber } style={ chapterStyles }>
            { chapterNumber < 10 ? `0${ chapterNumber }` : chapterNumber }
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withScrollListener,
  withWindowListener,
)(Hero);
