import React from 'react';
import PropTypes from 'prop-types';

import Chapter from './Chapter';
import ContextConsumer from '../../context/ContextConsumer';
import { GuideConsumer } from '../GuideContext';

const ChapterWrapper = ({
  index,
  slug,
  title,
  chapter_meta: {
    chapter_eyebrow,
    chapter_subtitle,
    chapter_read_time,
    chapter_featured_image,
    chapter_content,
  },
  shareCopy,
}) => (
  <ContextConsumer>
    { context => (
      <GuideConsumer>
        { guideContext => (
          <Chapter
            index={ index }
            heroData={ {
              localFile: chapter_featured_image && chapter_featured_image.localFile,
            } }
            introData={ {
              title,
              eyebrow: chapter_eyebrow,
              subtitle: chapter_subtitle,
              timeToRead: chapter_read_time,
            } }
            contentData={ chapter_content }
            shareCopy={ shareCopy }
            slug={ slug }
            { ...context.actions }
            { ...context.state }
            { ...guideContext.actions }
          />
        ) }
      </GuideConsumer>
    ) }
  </ContextConsumer>
);

ChapterWrapper.propTypes = {
  index: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  chapter_meta: PropTypes.shape({
    chapter_eyebrow: PropTypes.string.isRequired,
    chapter_subtitle: PropTypes.string.isRequired,
    chapter_read_time: PropTypes.string.isRequired,
    chapter_featured_image: PropTypes.object,
    chapter_content: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
};

export default ChapterWrapper;
