import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import SubTopicLayout from './SubTopicLayout';

const SubTopicLayoutWrapper = ({
  children,
  slug,
  yoast,
}) => (
  <StaticQuery
    query={ graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    ` }
    render={ data => (
      <SubTopicLayout
        data={ data }
        slug={ slug }
        yoast={ yoast }
      >
        { children }
      </SubTopicLayout>
    ) }
  />
);

SubTopicLayoutWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  slug: PropTypes.string.isRequired,
  yoast: PropTypes.shape({
    title: PropTypes.string,
    metadesc: PropTypes.string,
  }),
};

export default SubTopicLayoutWrapper;
