import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import cx from 'classnames';

import { scrollWindowTo } from '../../utils/dom';

import { HEADER_HEIGHT } from '../../constants/header';

import ChapterContent from '../ChapterContent';
import CTABlurGate from '../CTABlurGate';
import Hero from '../Hero';
import Intro from '../Intro';
import withWindowListener from '../withWindowListener';
import withScrollListener from '../withScrollListener';

import styles from './index.module.css';

class Chapter extends Component {
  static propTypes = {
    activeChapter: PropTypes.string.isRequired,
    contentData: PropTypes.array.isRequired,
    hasChapterCTA: PropTypes.bool.isRequired,
    hasFullscreenCTA: PropTypes.bool.isRequired,
    heroData: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    introData: PropTypes.object.isRequired,
    isContentGated: PropTypes.bool.isRequired,
    onChangeGuideActiveChapter: PropTypes.func,
    onChangeChapterScrollPct: PropTypes.func.isRequired,
    onChangeScrollToChapter: PropTypes.func.isRequired,
    scroll: PropTypes.number.isRequired,
    scrollToChapter: PropTypes.string.isRequired,
    shareCopy: PropTypes.string,
    viewport: PropTypes.string.isRequired,
  };

  state = {
    distFromTop: 0,
    isChapterActive: false,
  }

  componentDidUpdate(prevProps) {
    const {
      activeChapter,
      hasFullscreenCTA,
      isContentGated,
      scrollToChapter,
      scroll,
      slug,
      viewport,
    } = this.props;
    const { distFromTop } = this.state;

    if (scrollToChapter !== prevProps.scrollToChapter &&
      scrollToChapter === slug
    ) {
      this.scrollToChapter();
    }

    if (prevProps.scroll !== scroll && (!hasFullscreenCTA || !isContentGated)) {
      this.handleScroll();
    }

    if (!isContentGated && prevProps.isContentGated && activeChapter === slug) {
      const dist = this.wrapperEl.offsetTop - HEADER_HEIGHT[viewport] + distFromTop;
      window.scroll(0, dist);
    }
  }

  render() {
    const {
      contentData,
      hasChapterCTA,
      heroData,
      index,
      introData,
      isContentGated,
      shareCopy,
      slug,
    } = this.props;
    const { isChapterActive } = this.state;

    const isCTAVisible = hasChapterCTA && isContentGated;

    const wrapperClasses = cx({
      [styles.wrapper]: true,
      [styles.gated]: isCTAVisible,
    });

    return (
      <div className={ wrapperClasses } ref={ r => { this.wrapperEl = r; } }>
        { heroData.localFile &&
          <Hero { ...heroData } index={ index } />
        }
        <Intro { ...introData } />
        <ChapterContent
          content={ contentData }
          shareCopy={ shareCopy }
          slug={ slug }
        />
        { isCTAVisible &&
          <CTABlurGate chapter={ slug } isActive={ isChapterActive } />
        }
      </div>
    );
  }

  scrollToChapter = () => {
    const { viewport } = this.props;


    const top = this.wrapperEl.offsetTop - HEADER_HEIGHT[viewport];

    scrollWindowTo({
      top,
      duration: 1000,
    }, this.handleScrollToChapterEnd);
  }

  handleScroll = () => {
    const {
      activeChapter,
      onChangeGuideActiveChapter,
      onChangeChapterScrollPct,
      scroll,
      slug,
      viewport,
    } = this.props;
    const { isChapterActive } = this.state;

    const top = this.wrapperEl.offsetTop - HEADER_HEIGHT[viewport];
    const bottom = top + this.wrapperEl.offsetHeight;

    const isActive = scroll >= top && scroll < bottom;

    if (isActive) {
      const scrollPct = (scroll - top) / this.wrapperEl.offsetHeight;
      onChangeChapterScrollPct(scrollPct);

      this.setState({
        distFromTop: scroll - top,
        isChapterActive: true,
      });

      if (slug !== activeChapter && onChangeGuideActiveChapter) {
        onChangeGuideActiveChapter(slug);
      }
    } else if (isChapterActive && !isActive) {
      this.setState({
        isChapterActive: false,
      });
    }
  }

  handleScrollToChapterEnd = () => {
    const { onChangeScrollToChapter } = this.props;

    onChangeScrollToChapter('');
  }
}

export default compose(
  withScrollListener,
  withWindowListener,
)(Chapter);
