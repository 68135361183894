import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Context from '../../context/Context';

import styles from './index.module.css';

const Intro = ({
  eyebrow,
  title,
  subtitle,
  timeToRead,
}) => {
  const { state } = useContext(Context);

  const wrapperClasses = cx({
    [styles.wrapper]: true,
    [styles.themeDark]: state.theme === 'dark',
  });

  return (
    <div className={ wrapperClasses }>
      <div className={ styles.inner }>
        <h5 className={ styles.eyebrow }>
          { eyebrow }
        </h5>
        <h1
          className={ styles.title }
          dangerouslySetInnerHTML={ { __html: title } }
        />
        { subtitle &&
          <p className={ styles.subtitle }>
            { subtitle }
          </p>
        }
        { timeToRead &&
          <p className={ styles.timeToRead }>
            { timeToRead }
          </p>
        }
      </div>
    </div>
  );
};

Intro.propTypes = {
  eyebrow: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  timeToRead: PropTypes.string,
};

export default Intro;
