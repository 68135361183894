import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Context from '../../context/Context';

import ClipboardBtn from '../ClipboardBtn';
import ContentList from '../ContentList';

import styles from './index.module.css';

const ChapterContent = ({
  content,
  shareCopy,
  slug,
}) => {
  const { state } = useContext(Context);

  const [shareLink, setShareLink] = useState('');
  useEffect(() => {
    setShareLink(window.location.href);
  });

  const wrapperClasses = cx({
    [styles.wrapper]: true,
    [styles.themeDark]: state.theme === 'dark',
  });

  return (
    <div className={ wrapperClasses }>
      <div className={ styles.inner }>
        <ContentList content={ content } slug={ slug } />
        <div className={ styles.shareBtnWrapper }>
          <ClipboardBtn
            className={ styles.shareBtn }
            filled
            label={ shareCopy || 'Share this chapter' }
            shareLink={ shareLink }
          />
        </div>
      </div>
    </div>
  );
};

ChapterContent.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({
    content_type: PropTypes.string.isRequired, // TODO check for actual accepted types
  })).isRequired,
  shareCopy: PropTypes.string,
  slug: PropTypes.string.isRequired,
};

export default ChapterContent;
