import React from 'react';

import Hero from './Hero';

import ContextConsumer from '../../context/ContextConsumer';

const HeroWrapper = props => (
  <ContextConsumer>
    { context => (
      <Hero
        { ...props }
        { ...context.state }
      />
    ) }
  </ContextConsumer>
);

export default HeroWrapper;
